import dayjs from 'dayjs';

export interface ICustomAttributeValue {
  id?: number;
  textValue?: string | null;
  booleanValue?: boolean | null;
  dateValue?: string | null;
  enumerationValue?: string | null;
  multiselectValue?: string | null;
  numberValue?: number | null;
}

export const defaultValue: Readonly<ICustomAttributeValue> = {
  booleanValue: false,
};
