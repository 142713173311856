import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getProfileDefinitions } from 'app/entities/profile-definition/profile-definition.reducer';
import ExpressionBuilder from 'app/shared/components/expression-builder/expression-builder';
import { ValueType } from 'app/shared/components/expression-builder/types';
import { IStandardPolicyRule } from 'app/shared/model/standard-policy-rule.model';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './standard-policy-rule.reducer';

import { apiUrl as profileDefinitionsUrl } from 'app/entities/profile-definition/profile-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { convertReactSelectValuesToEntityIds } from 'app/shared/util/entity-utils';
import { ENTITY_NAME } from 'app/shared/model/standard-policy-rule.model';
import moment from 'moment';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import { IProfileDefinition } from 'app/shared/model/profile-definition.model';

export const StandardPolicyRuleUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const standardPolicyRuleEntity: IStandardPolicyRule = useAppSelector(state => state.standardPolicyRule.entity);
  const profileDefinitionEntities: IStandardPolicyRule = useAppSelector(state => state.profileDefinition.entities);
  const loading = useAppSelector(state => state.standardPolicyRule.loading);
  const updating = useAppSelector(state => state.standardPolicyRule.updating);
  const updateSuccess = useAppSelector(state => state.standardPolicyRule.updateSuccess);

  const [conditionExpression, setConditionExpression] = useState(null);
  const [profileStartDate, setProfileStartDate] = useState('');
  const [profileEndDate, setProfileEndDate] = useState('');

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProfileDefinitions({}));
  }, []);

  useEffect(() => {
    setConditionExpression(standardPolicyRuleEntity.conditionExpression);
    formReset(defaultValues());
  }, [standardPolicyRuleEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const locale = useAppSelector(state => state.locale.currentLocale);

  const saveEntity = values => {
    const entity = {
      ...standardPolicyRuleEntity,
      ...convertReactSelectValuesToEntityIds(values),
      conditionExpression: conditionExpression,
      validFrom: values.validFrom ? moment(values.validFrom).format('YYYY-MM-DD') : null, // Format validFrom for server
      validTo: values.validTo ? moment(values.validTo).format('YYYY-MM-DD') : null, // Format validTo for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          validFrom: moment().format(APP_GLOBAL_DATE_FORMAT),
          validTo: null,
        }
      : {
          ...standardPolicyRuleEntity,
          profile: standardPolicyRuleEntity?.profile,
        };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
    setValue,
  } = useForm<IStandardPolicyRule>({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchValidFrom = watch('validFrom');
  const watchValidTo = watch('validTo');
  const watchProfile = watch('profile');

  useEffect(() => {}, [profileStartDate, profileEndDate]);
  useEffect(() => {
    if (watchProfile === undefined) return;

    Object.keys(profileDefinitionEntities).map(index => {
      const profile = profileDefinitionEntities[index] as IProfileDefinition;
      if (profile.id === watchProfile.id) {
        setProfileStartDate(profile.startDate);
        setProfileEndDate(profile.endDate);

        /**
         * @condition standardPolicyRuleEntity.validTo > profile.startDate
         * If you want to set minimum date based on profile's start date, when the user is changing the profile
         * If standard policy rule's "valid to" date is greater than profile's start date then the user won't be able to select any value within date picker
         */
        if (!watchValidTo) {
          setValue('validTo', profile.endDate);
        }
      }
    });
  }, [watchProfile, profileDefinitionEntities]);

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.standardPolicyRule.home.createOrEditLabel" data-cy="StandardPolicyRuleCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.standardPolicyRule.home.createOrEditLabel">Create or edit a StandardPolicyRule</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="standard-policy-rule-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.standardPolicyRule.name')}
                id="standard-policy-rule-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <FormGroup key={`conditionExpression-${id}`}>
                <Label>
                  <Translate contentKey="iamdentityApp.standardPolicyRule.conditionExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={conditionExpression}
                  restrictedValueType={ValueType.BOOLEAN}
                  onChange={expression => setConditionExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.standardPolicyRule.profile" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="profile"
                  url={profileDefinitionsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validFrom"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="profile-definition-validFrom" className="form-label">
                        {translate('iamdentityApp.standardPolicyRule.validFrom')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          disabled={watchProfile == null}
                          minDate={new Date(profileStartDate)}
                          maxDate={watchValidTo ? moment(watchValidTo, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="profile-definition-validFrom"
                          data-cy="validFrom"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validTo"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="profile-definition-validTo" className="form-label">
                        {translate('iamdentityApp.standardPolicyRule.validTo')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          disabled={watchProfile == null}
                          minDate={watchValidFrom ? moment(watchValidFrom, 'YYYY-MM-DD').toDate() : null}
                          maxDate={new Date(profileEndDate)}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="profile-definition-validTo"
                          data-cy="validTo"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default StandardPolicyRuleUpdate;
