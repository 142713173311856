import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import ExpressionBuilder from 'app/shared/components/expression-builder/expression-builder';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { IProfileUserIdentity, ENTITY_NAME } from 'app/shared/model/profile-user-identity.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { createEntity, getEntity, resetEntity, updateEntity } from './profile-user-identity.reducer';

import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';
import { apiUrl as profileDefinitionsUrl } from 'app/entities/profile-definition/profile-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { ValueType } from 'app/shared/components/expression-builder/types';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ISearchParamsObject {
  application?: { id: number; name: string };
  profileDefinition?: { id: number; name: string };
}

export const ProfileUserIdentityUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));

  const { profileUserIdentityId: id } = useParams<'profileUserIdentityId'>();
  const isNew = id === undefined;

  const [userNameExpression, setUserNameExpression] = useState(null);
  const [alternativeUserNameExpression, setAlternativeUserNameExpression] = useState(null);
  const [associatedEmailAddressExpression, setAssociatedEmailAddressExpression] = useState(null);
  const [displayNameExpression, setDisplayNameExpression] = useState(null);
  const [initialPasswordExpression, setInitialPasswordExpression] = useState(null);
  const [mustChangePasswordOnFirstLoginExpression, setMustChangePasswordOnFirstLoginExpression] = useState(null);
  const [dateNextChangePasswordExpression, setDateNextChangePasswordExpression] = useState(null);

  const profileUserIdentityEntity: IProfileUserIdentity = useAppSelector(state => state.profileUserIdentity.entity);
  const loading = useAppSelector(state => state.profileUserIdentity.loading);
  const updating = useAppSelector(state => state.profileUserIdentity.updating);
  const updateSuccess = useAppSelector(state => state.profileUserIdentity.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);

  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    setUserNameExpression(profileUserIdentityEntity.userNameExpression);
    setAlternativeUserNameExpression(profileUserIdentityEntity.alternativeUserNameExpression);
    setAssociatedEmailAddressExpression(profileUserIdentityEntity.associatedEmailAddressExpression);
    setDisplayNameExpression(profileUserIdentityEntity.displayNameExpression);
    setInitialPasswordExpression(profileUserIdentityEntity.initialPasswordExpression);
    setMustChangePasswordOnFirstLoginExpression(profileUserIdentityEntity.mustChangePasswordOnFirstLoginExpression);
    setDateNextChangePasswordExpression(profileUserIdentityEntity.dateNextChangePasswordExpression);
    formReset(defaultValues());
  }, [profileUserIdentityEntity]);

  const locale = useAppSelector(state => state.locale.currentLocale);
  const [errorMessage] = useState('');

  const saveEntity = values => {
    if (!userNameExpression) {
      toast.error(translate('iamdentityApp.profileUserIdentity.userNameExpressionErrorMessage'));
      return;
    }

    const start = moment(values.startDate, convertDateFormat(locale, 'instant'));
    const end = moment(values.endDate, convertDateFormat(locale, 'instant'));

    if (start.isAfter(end)) {
      toast.error(translate('iamdentityApp.profileUserIdentity.startDateErrorMessage'));
      return;
    } else if (end.isBefore(start)) {
      toast.error(translate('iamdentityApp.profileUserIdentity.endDateErrorMessage'));
      return;
    }

    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);

    const entity = {
      ...profileUserIdentityEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        profileUserIdentityEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
      userNameExpression: userNameExpression ? userNameExpression : null,
      alternativeUserNameExpression: alternativeUserNameExpression,
      associatedEmailAddressExpression: associatedEmailAddressExpression,
      displayNameExpression: displayNameExpression,
      initialPasswordExpression: initialPasswordExpression,
      mustChangePasswordOnFirstLoginExpression: mustChangePasswordOnFirstLoginExpression,
      dateNextChangePasswordExpression: dateNextChangePasswordExpression,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment(new Date(), convertDateFormat(locale, 'instant')).toDate(),
          endDate: null,
          ...searchParamsObject,
        }
      : {
          ...profileUserIdentityEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, profileUserIdentityEntity),
          startDate: convertDateTimeFromServer(profileUserIdentityEntity.startDate),
          endDate: convertDateTimeFromServer(profileUserIdentityEntity.endDate),
          profileDefinition: profileUserIdentityEntity?.profileDefinition,
          application: profileUserIdentityEntity?.application,
          ...searchParamsObject,
        };

    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  const translateTimeElement = <Translate contentKey="global.time">Time</Translate>;
  const convertTimeElementToString = renderToString(translateTimeElement);
  const timeTranslateReplace = convertTimeElementToString.replace('<span>', '');
  const timeReplaceFinal = timeTranslateReplace.replace('</span>', '');

  return (
    <Card className="jh-card pad-1">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.profileUserIdentity.home.createOrEditLabel" data-cy="ProfileUserIdentityCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.profileUserIdentity.home.createOrEditLabel">
              Create or edit a ProfileUserIdentity
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="profile-user-identity-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.profileDefinition" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="profileDefinition"
                  url={profileDefinitionsUrl}
                  isDisabled={!!searchParamsObject?.profileDefinition}
                  queryProps={{
                    name: 'uniqueKey',
                    secondName: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.profileUserIdentity.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  url={applicationUrl}
                  isDisabled={!!searchParamsObject?.application}
                  validate={{ required: true }}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.profileUserIdentity.userNameExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={userNameExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setUserNameExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.alternativeUserNameExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={alternativeUserNameExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setAlternativeUserNameExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.associatedEmailAddressExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={associatedEmailAddressExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setAssociatedEmailAddressExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.displayNameExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={displayNameExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setDisplayNameExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.initialPasswordExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={initialPasswordExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setInitialPasswordExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.mustChangePasswordOnFirstLoginExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={mustChangePasswordOnFirstLoginExpression}
                  restrictedValueType={ValueType.BOOLEAN}
                  onChange={expression => setMustChangePasswordOnFirstLoginExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentity.dateNextChangePasswordExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  restrictedValueType={ValueType.DATE}
                  initialState={dateNextChangePasswordExpression}
                  onChange={expression => setDateNextChangePasswordExpression(expression)}
                />
              </FormGroup>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="profile-user-identity-startDate" className="form-label">
                        {translate('iamdentityApp.profileUserIdentity.startDate')}
                      </label>
                      <DatePicker
                        popperClassName="datepicker-calendar-popper"
                        maxDate={
                          watchEndDate
                            ? moment(watchEndDate).toDate()
                            : profileUserIdentityEntity.endDate
                            ? moment(profileUserIdentityEntity.endDate).toDate()
                            : null
                        }
                        portalId="root-portal"
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        timeInputLabel={timeReplaceFinal + ':'}
                        showTimeInput
                        className="form-control"
                        ref={ref}
                        id="profile-user-identity-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="profile-user-identity-endDate" className="form-label">
                        {translate('iamdentityApp.profileUserIdentity.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          popperClassName="datepicker-calendar-popper"
                          minDate={
                            watchStartDate
                              ? moment(watchStartDate).toDate()
                              : profileUserIdentityEntity.startDate
                              ? moment(profileUserIdentityEntity.startDate).toDate()
                              : null
                          }
                          portalId="root-portal"
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          timeInputLabel={timeReplaceFinal + ':'}
                          showTimeInput
                          className="form-control"
                          ref={ref}
                          id="profile-user-identity-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register: register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `profile-user-identity-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
      {errorMessage && <ToastContainer />}
    </Card>
  );
};

export default ProfileUserIdentityUpdate;
